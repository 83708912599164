<template>
  <div class="navigation navbar navbar-light py-lg-7 flex-shrink-0">
    <!-- Brand -->
    <router-link :to="{ name: 'home' }" class="d-none d-lg-block">
      <img :src="logoURL" class="mx-auto" style="height: 46px;" />
    </router-link>

    <!-- Menu -->
    <ul
      class="nav navbar-nav d-flex flex-column h-100 w-100 py-2 py-lg-0"
      role="tablist"
    >
      <router-link
        v-for="(inbox, index) in filteredInboxes"
        :key="inbox.name"
        :to="
          inbox.path || {
            name: 'inbox',
            params: { inboxName: inbox.name, statusId: 'open' },
          }
        "
        class="nav-link position-relative p-0 py-3"
        :class="{ active: isInboxActive(inbox.name), 'mt-lg-8': index === 0 }"
      >
        <div class="d-flex justify-content-center align-items-center">
          <span class="text-left" style="min-width: 3rem">
            <font-awesome-icon :icon="icon(inbox)" class="icon-md" />
          </span>
          <span
            class="badge font-weight-bold"
            :class="{
              'badge-primary': isInboxActive(inbox.name),
              'badge-secondary': !isInboxActive(inbox.name),
            }"
            style="font-size: 0.85rem"
          >
            <span>{{ counter(inbox) }}</span>
          </span>
        </div>
      </router-link>

      <li class="mt-lg-auto"></li>

      <li class="nav-item" v-if="$p('prescription', 'list')">
        <router-link
          :to="{ name: 'prescriptions' }"
          class="nav-link position-relative p-0 py-5"
          active-class="active"
          style="font-size: 0.85rem"
        >
          <font-awesome-icon
            :icon="['fad', 'clipboard-prescription']"
            class="fa-2x"
          />
        </router-link>
      </li>

      <li
        class="nav-item mt-lg-0"
        v-if="$p('agent', 'list') && !isCurrentAgentBetaTester"
      >
        <router-link
          :to="{ name: 'agents' }"
          class="nav-link position-relative p-0 py-5"
          active-class="active"
          style="font-size: 0.85rem"
        >
          <font-awesome-icon :icon="['fad', 'user-md']" class="fa-2x" />
        </router-link>
      </li>

      <li class="nav-item mt-lg-0" v-if="$p('admin', 'showLegacyUI')">
        <router-link
          :to="{ name: 'admin' }"
          class="nav-link position-relative p-0 py-5"
          active-class="active"
          style="font-size: 0.85rem"
        >
          <font-awesome-icon :icon="['fad', 'tools']" class="fa-2x" />
        </router-link>
      </li>

      <li class="nav-item mt-lg-0" v-if="$p('admin', 'showNewUI')">
        <a
          :href="adminUrl"
          class="nav-link position-relative p-0 py-5"
          style="font-size: 0.85rem"
          target="_blank"
        >
          <font-awesome-icon :icon="['fad', 'tools']" class="fa-2x" />
        </a>
      </li>

      <li class="nav-item mt-lg-6">
        <router-link
          to="/settings/profile"
          class="nav-link position-relative p-0 py-2"
        >
          <avatar :user="currentAgent" size="sm" />
        </router-link>
      </li>
    </ul>
    <!-- Menu -->
  </div>
</template>

<script>
import { ADMIN_URL } from '@/shared/config';
import { listInboxes } from '@/shared/inboxes';
import { showBadge } from '@/plugins/favicon';
import { timeUntilNextMorning } from '@/shared/utils';

export default {
  name: 'Navbar',
  data() {
    return {
      rooms: [],
      snoozedRooms: [],
      favoriteRooms: [],
      inboxes: listInboxes(),
    };
  },
  mounted() {
    this.fetchOpenRooms();
    this.fetchSnoozedRooms();
    this.fetchFavoriteRooms();
  },
  destroyed() {
    clearTimeout(this.fetchingAwakeRoomsJob);
  },
  computed: {
    filteredInboxes() {
      return this.inboxes.filter(
        inbox => !inbox.betaTesterOnly || this.isCurrentAgentBetaTester
      );
    },
    inboxName() {
      return this.$route.params.inboxName || this.$route.meta.inboxName;
    },
    adminUrl() {
      return ADMIN_URL;
    },
  },
  methods: {
    fetchOpenRooms() {
      this.$bind('rooms', this.services.room.findAll('all', 'open'));
    },
    fetchSnoozedRooms() {
      this.$bind(
        'snoozedRooms',
        this.services.room.findAll('snoozed', 'awake')
      );
      // low-tech solution to make sure that we get a fresh list
      // of snoozed rooms every morning at 1:00am.
      if (this.fetchingSnoozedRoomsJob)
        clearTimeout(this.fetchingSnoozedRoomsJob);
      this.fetchingSnoozedRoomsJob = setTimeout(
        this.fetchSnoozedRooms.bind(this),
        timeUntilNextMorning()
      );
    },
    fetchFavoriteRooms() {
      this.$bind(
        'favoriteRooms',
        this.services.room.findAllFavorites(this.currentAgent)
      );
    },
    counter(inbox) {
      return typeof inbox.count !== 'undefined' ? inbox.count : '.';
    },
    icon(inbox) {
      return inbox.icon;
    },
    isInboxActive(inboxName) {
      return inboxName === this.inboxName;
    },
    refreshCounters(rooms) {
      var newInboxes = [...this.inboxes];
      var counters = {
        you: 0,
        unassigned: 0,
        assigned: 0,
        snoozed: this.snoozedRooms.length,
        favorites: this.favoriteRooms.length,
      };
      this.services.room.filter(rooms).forEach(room => {
        if (room.agent?.id) {
          counters.assigned++;
          if (room.agent.id === this.currentAgent.id) counters.you++;
        } else counters.unassigned++;
      });

      // the counter put in the browser tab pane depends whether the agent is an admin or not
      showBadge(this.isCurrentAgentAdmin ? counters.unassigned : counters.you);

      newInboxes.forEach(inbox => (inbox.count = counters[inbox.name]));
      this.inboxes = newInboxes;
    },
    refreshSnoozedCounter(snoozedRooms) {
      var newInboxes = [...this.inboxes];
      newInboxes.forEach(inbox => {
        if (inbox.name === 'snoozed') inbox.count = snoozedRooms.length;
      });
      this.inboxes = newInboxes;
    },
    refreshFavoritesCounter(favoriteRooms) {
      var newInboxes = [...this.inboxes];
      newInboxes.forEach(inbox => {
        if (inbox.name === 'favorites') inbox.count = favoriteRooms.length;
      });
      this.inboxes = newInboxes;
    },
  },
  watch: {
    rooms: {
      immediate: false,
      handler(newRooms) {
        // Vuefire always fires a first request with no elements
        if (newRooms.length > 0) this.refreshCounters(newRooms);
      },
    },
    snoozedRooms(newRooms) {
      this.refreshSnoozedCounter(newRooms);
    },
    favoriteRooms(newRooms) {
      this.refreshFavoritesCounter(newRooms);
    },
  },
};
</script>
