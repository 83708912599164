<template>
  <div class="layout" v-if="currentAgent">
    <Navbar />

    <div class="sidebar sidebar--alt sidebar--h-borders inbox flex-shrink-0">
      <div class="tab-content h-100">
        <transition :name="transitionName" mode="out-in">
          <router-view name="sidebar"></router-view>
        </transition>
      </div>
    </div>

    <transition name="fade" mode="out-in">
      <router-view />
    </transition>
  </div>
</template>

<script>
import Navbar from '@/components/navbar.vue';
import { mapState, mapActions } from 'vuex';

const DEFAULT_TRANSITION = 'fade';

export default {
  name: 'workspace',
  components: { Navbar },
  data() {
    return {
      transitionName: DEFAULT_TRANSITION,
    };
  },
  created() {
    this.$router.beforeEach((to, from, next) => {
      var transitionName = to.meta.transitionName || from.meta.transitionName;

      if (typeof transitionName === 'function')
        transitionName = transitionName(to, from);

      if (transitionName === 'slide') {
        const toDepth = to.path.split('/').length;
        const fromDepth = from.path.split('/').length;
        transitionName = toDepth < fromDepth ? 'slide-right' : 'slide-left';
      }

      this.transitionName = transitionName || DEFAULT_TRANSITION;

      next();
    });
  },
  computed: {
    ...mapState(['message']),
  },
  methods: {
    ...mapActions(['signOutAction']),
  },
  watch: {
    authenticated(newValue) {
      if (!newValue) {
        this.$router.push({ name: 'signIn' });
      }
    },
    'currentAgent.isDisabled'(isDisabled) {
      if (isDisabled) this.signOutAction();
    },
  },
};
</script>
