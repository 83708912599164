import Favico from 'favico.js';

const favicon = new Favico({
  position: 'up',
  animation: 'none',
  type: 'rectangle',
});

export const showBadge = counter => {
  try {
    favicon.badge(counter);
  } catch (error) {
    console.log("[WARNING] can't update the favicon badge, reason: ", error);
  }
};

export default favicon;
